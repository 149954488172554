import Footer from "components/Footer";
import Header from "components/Header";
import './styles.css';
import useAuth, { useForceReloadAfterLogin, openGoogleLogin } from "services/useAuth";
import { chromeWebstoreURL, useExtensionInstalled, useIsSidePanelOpen } from "services/useExtension";
import H1 from "./H1";

export default function Home() {
  const user = useAuth()
  
  // TODO: check extension version
  const extensionInstalled = useExtensionInstalled()
  useForceReloadAfterLogin()
  
  const isSidePanelOpen = useIsSidePanelOpen()
  const showOpenSidePanelButton = !isSidePanelOpen && user && extensionInstalled

  if (showOpenSidePanelButton) {
    // Add more detailed logging
    console.log('Extension state:', {
      extensionInstalled,
      hasWindow: typeof window !== 'undefined',
      hasChromeAutopilot: !!window?.ChromeAutopilot,
      hasMethod: !!window?.ChromeAutopilot?.sendToContentScript
    })

    try {
      if (window?.ChromeAutopilot?.sendToContentScript) {
        window.ChromeAutopilot.sendToContentScript('showOpenSidePanelButton')
      } else {
        // Wait a short time and try again, in case the content script hasn't loaded yet
        setTimeout(() => {
          if (window?.ChromeAutopilot?.sendToContentScript) {
            window.ChromeAutopilot.sendToContentScript('showOpenSidePanelButton')
          } else {
            console.warn('ChromeAutopilot.sendToContentScript still not available after delay')
          }
        }, 1000)
      }
    } catch (error) {
      console.error('Error sending message to content script:', error)
    }
  }
  
  return (
    <>
      <Header />

      <div className="content">
        <section className="hero">
          <H1 />
          <div className="subline">
          Chat with any webpage, browse by voice, automate tasks, research and scrape the web.
          </div>
        </section>

        {!user && (
          <section className="heroButton">
            <div className="signInGoogle" onClick={openGoogleLogin}></div>
            {/* <div>No credit card needed -- try for free!</div> */}
          </section>
        )}
        {user && !extensionInstalled && (
          <section className="heroButton">
            <div className="get-button">
              <a href={chromeWebstoreURL}>Get Chrome Autopilot →</a>
            </div>
            <div>On the next page, click 'Add to Chrome' to install the Chrome Extension</div>
          </section>
        )}
        { showOpenSidePanelButton && (
          <section className="heroButton">
            <div className="get-button" id="openSidePanelContainer"></div>
          </section>
        )}
        {isSidePanelOpen && user && extensionInstalled && (!user.plan || user.plan === 'free') && (
          <section className="heroButton">
            <div className="get-button">
              <a href='/checkout'>✨ Upgrade to Premium</a>
            </div>
            <div>Subscribe for $20/month</div>
          </section>
        )}

        <section className="bg">
          <div className="video-container">
            <iframe
              src="https://www.youtube.com/embed/Ne9iM3AzKKw?loop=1&rel=0&playlist=Ne9iM3AzKKw&controls=1&end=60&autoplay=1&mute=1"
              title="Chrome Autopilot Demo"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </section>
          
      </div>
      <Footer />
    </>
  )
}

